/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function Footer() {
  return (
    <>
      <footer className="footer ">
        <Container>
          <Row  >
            {/* <Col className="ml-auto mr-auto" md="4" style={{ textAlign: 'right', color: '#AFAFAE'}}>
              <div>Paradise Law Group offers opinions in the following jurisdictions: CA, DC, DE, FL, IL, IN, MD, NY. We also provide non-consolidation opinions.
         </div>
            </Col> */}

            <Col className="ml-auto mr-auto" md="12" style={{ textAlign: 'center', color: '#313131'}}>
              <div>© 2025 Paradise Law Group, PC.  All Rights Reserved. &nbsp; &nbsp;   |  &nbsp; &nbsp; Attorney Advertising
  
             </div>
              <div >  

              </div>
            </Col>

            {/* <Col className="ml-auto mr-auto" md="4" style={{ textAlign: 'center', color: '#AFAFAE' }}>
              <div>
                </div>
            </Col> */}
    
            {/* <div className="credits ml-auto">
              <span className="copyright">
                © {new Date().getFullYear()}
                , made with <i className="fa fa-heart heart" /> by Paradise Law Group
              </span>
            </div> */}
          </Row>




        </Container>
      </footer>
    </>
  );
}

export default Footer;
